import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { Link } from "gatsby"

import { slugify } from "../utils/utils"
import measurements from "../styles/measurements"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    justify-content: center;
  }
`

const ImgWrapper = styled.div`
  margin: 0 2rem 2rem 0;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    margin: 2rem 0 0 0;

    &:last-child {
      margin: 2rem 0 2rem 0;
    }
  }
`

let height = 30

const Img = styled(Image)`
  height: ${height}rem;
`

const Gallery = gallery => {
  let images =
    gallery.props.allContentfulDrawing ||
    gallery.props.allContentfulPrint ||
    gallery.props.allContentfulObject

  let galleryType = checkGalleryType(gallery)

  return (
    <Grid>
      {images.edges.map((image, i) => {
        let aspectRatio = image.node.image.fluid.aspectRatio
        return (
          <ImgWrapper
            key={`${image.node.title}_${i}`}
            style={{ width: `${height * aspectRatio}rem` }}
          >
            <Link
              to={`/${galleryType}/${slugify(image.node.image.file.fileName)}`}
            >
              <Img alt={image.node.title} fluid={image.node.image.fluid}></Img>
            </Link>
          </ImgWrapper>
        )
      })}
    </Grid>
  )
}

export default Gallery

const checkGalleryType = gallery => {
  if (gallery.props.allContentfulDrawing) {
    return "drawings"
  } else if (gallery.props.allContentfulPrint) {
    return "prints"
  } else if (gallery.props.allContentfulObject) {
    return "objects"
  }
}
